import {useState} from 'react'
import {Button, Col, Form, Input, Row} from 'antd'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import SelectDtoDialog from '@/pages/MainPage/dialogs/SelectDtoDialog'

const useMakeApi = () => {
    return (args) => runAsync(
        () => http.post('/bmsvrs/genApi', args),
        {action: '生成程序能力'}
    )
}

const SelectDto = ({prjId, sVer, onSelect, ...props}) => {
    const [visible, setVisible] = useState(false)
    const handleClick = () => setVisible(true)

    return (
        <div
            {...props}
            onKeyDown={e => e.stopPropagation()}
        >
            <Button
                type="link"
                onClick={handleClick}
            >
                选择
            </Button>

            <SelectDtoDialog
                dataType="Ref"
                prjId={prjId}
                sVer={sVer}
                setVisible={setVisible}
                visible={visible}
                onCall={onSelect}
            />
        </div>
    )
}

const ModalFormParams = ({input, output, prjId, sVer, onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const handleSelectInputDto = ({dtoName, dtoUserCode}) => {
        form.setFieldsValue({
            algInputDtoCode: dtoUserCode,
            algInputDtoName: dtoName,
        })
    }

    const handleSelectOutputDto = ({dtoName, dtoUserCode}) => {
        form.setFieldsValue({
            algOutputDtoCode: dtoUserCode,
            algOutputDtoName: dtoName,
        })
    }

    return (
        <Modal
            title="输入DTO信息"
            width={600}
            {...props}
            onOk={handleOk}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                {
                    input &&

                    <Row gutter={24}>
                        <Col>
                            <Form.Item
                                label="入参DTO代码"
                                name="algInputDtoCode"
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item
                                label="入参DTO名称"
                                name="algInputDtoName"
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item label=" ">
                                <SelectDto
                                    prjId={prjId}
                                    sVer={sVer}
                                    onSelect={handleSelectInputDto}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                }

                {
                    output &&

                    <Row gutter={24}>
                        <Col>
                            <Form.Item
                                label="出参DTO代码"
                                name="algOutputDtoCode"
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item
                                label="出参DTO名称"
                                name="algOutputDtoName"
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item label=" ">
                                <SelectDto
                                    prjId={prjId}
                                    sVer={sVer}
                                    onSelect={handleSelectOutputDto}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>
    )
}

export default function PropertiesPaneButtonMakeApi(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const makeApi = useMakeApi()

    if ('_SVR_MAP' !== map.data.mapTypeCode) {
        return null
    }

    const findParams = (alg, dict) => {
        const params = {}

        for (const node of map.walkDown(alg, {excludeTarget: true})) {
            const {bizNodeType} = node.data

            if (dict[bizNodeType]) {
                const key = dict[bizNodeType]

                if (node.firstChild) {
                    const value = map.behaviours.getNodeText(node.firstChild)
                    params[key] = value
                }
                else {
                    params[key] = ''
                }
            }
        }

        return params
    }

    const algs = [...selectedNodes].filter((e) => e.data.pkid)

    const algList = algs.map((node) => {
        const params = findParams(node, {
            'ALG_INPUT': 'algInputParam',
            'ALG_OUTPUT': 'algOutputParam',
        })

        return {...node.data, ...params}
    })

    const svrIds = new Set(
        algList.map(({svrId}) => svrId)
    )

    if (1 !== svrIds.size) {
        return null
    }

    const handleClick = async () => {
        const make = async () => {
            const [pkid] = svrIds
            const fcs = await makeApi({algList, pkid})
            const {mapProp} = map.BizNode.FC

            await map.cmd(() => {
                const nodesToSelect = new Set()

                for (const [i, alg] of algs.entries()) {
                    const {
                        [mapProp]: _,
                        ...fc
                    } = fcs[i]

                    const algApi = (() => {
                        for (const child of map.children(alg)) {
                            const {bizNodeType} = child.data

                            if ('ALG_API' === bizNodeType) {
                                return child
                            }
                        }
                    })()

                    if (algApi) {
                        const data = {...fc, bizNodeType: 'FC'}
                        const node = map.importTree({data})
                        map.appendChild(algApi, node)
                        nodesToSelect.add(node)
                    }
                }

                map.selectNodes(nodesToSelect)
            })

            Modal.confirm({
                cancelText: '我已了解',
                content: '生成程序能力成功',
                okText: '去查看',
                onOk: () => window.open('/FcList'),
                title: '提示',
            })
        }

        if (1 < algList.length) {
            return make()
        }

        const {algInputParam, algOutputParam} = algList[0]
        const hasInput = /^D[OR]\$/.test(algInputParam)
        const hasOutput = /^D[OR]\$/.test(algOutputParam)

        if (hasInput || hasOutput) {
            const {prjId, sVer} = map.data

            const params = await openModal(
                <ModalFormParams
                    input={hasInput}
                    output={hasOutput}
                    prjId={prjId}
                    sVer={sVer}
                />
            )

            if (params) {
                Object.assign(algList[0], params)
                return make()
            }
        }
        else {
            return make()
        }
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >生成程序能力</Button>
    )
}
