import {useOpenModal} from '@/components/Modal/Modal.jsx'
import http from '@/biz/http.mjs'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import ModalSelectDesignItems from '../../../nodes/components/ModalSelectDesignItems.jsx'
import MenuButton from '../../MenuButton.jsx'
import QueryForm from './QueryFormSelectProducts.jsx'
import Table from './TableSelectProducts.jsx'

const useFetch = () => {

    return async (data) => {
        const items = await http.get('/dps/all', data)

        return items.map(
            ({mapTypeCode: bizNodeType, ...e}) => ({...e, bizNodeType})
        )
    }
}

export default function MenuButtonInsertProducts(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const fetch = useFetch()
    const {data: {mapTypeCode}} = map

    if (! (
        '_DES_MAP' === mapTypeCode &&
        1 === selectedNodes.size
    )) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType} = node.data

    if (! /^(AR|DES_(DES|OTH|TASK|TEST))$/.test(bizNodeType)) {
        return null
    }

    const handleClick = async () => {
        const items = await openModal(
            <MapContext.Provider value={map}>
                <ModalSelectDesignItems
                    fetch={fetch}
                    QueryForm={QueryForm}
                    Table={Table}
                    title="选择模件"
                    width="80%"
                />
            </MapContext.Provider>
        )

        if (! items) {
            return
        }

        await map.cmd(async () => {
            const nodesToSelect = new Set()

            for (const data of items) {
                const child = map.importTree({data})
                map.appendChild(node, child)
                const {bizNodeType} = child.data
                await map.BizNode[bizNodeType].onInsert(map, child)

                if (! child.isDeleted) {
                    nodesToSelect.add(child)
                }
            }

            map.logger.info('插入模件成功', [node])
            map.selectNodes(nodesToSelect)
        })
    }

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            插入最新模件
        </MenuButton>
    )
}
