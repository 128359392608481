import {Form} from 'antd'
import Checkbox from '@/components/Form/Checkbox.jsx'
import TextArea from '@/components/Form/TextArea.jsx'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'

export const canPublishProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canPublishProduct(node)
        )
    }
}

const ModalPublish = ({onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Modal
            title="发布模型制品"
            width={400}
            {...props}
            onOk={handleOk}
            onFinish={onOk}
            css={cssForm}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                <Form.Item
                    label="备注"
                    name="memo"
                >
                    <TextArea
                        autoSize={false}
                        rows={5}
                    />
                </Form.Item>

                <Form.Item name="upgradeDp">
                    <Checkbox>升级模型</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export const publishProduct = () => {
    const openModal = useOpenModal()

    return () => async function (nodes) {
        const extra = await openModal(<ModalPublish />)

        if (! extra) {
            return
        }

        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.publishProduct(nodes, extra),
                {action: '发布模型制品'},
            )
        )
    }
}
