import {Form} from 'antd'
import Checkbox from '@/components/Form/Checkbox.jsx'
import InputText from '@/components/Form/InputText.jsx'
import TextArea from '@/components/Form/TextArea.jsx'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import apiMap from '../../../apis/apiMap.mjs'

export const canPublishMap = () => () => {
    return function () {
        if (! /^DRAFT|REVISE$/.test(this.data.mapStsCode)) {
            this.logger.error('只有草稿/修订状态的地图才能发布')
            return false
        }

        if (! this.permission.has('write')) {
            this.logger.error('没有发布权限')
            return false
        }

        return true
    }
}

const ModalPublish = ({softwareVersion, onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Modal
            title="发布地图"
            width={400}
            {...props}
            onOk={handleOk}
            onFinish={onOk}
            css={cssForm}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                {
                    softwareVersion &&

                    <Form.Item
                        label="软件版本号"
                        name="softwareVersion"
                    >
                        <InputText />
                    </Form.Item>
                }

                <Form.Item
                    label="备注"
                    name="memo"
                >
                    <TextArea
                        autoSize={false}
                        rows={5}
                    />
                </Form.Item>

                <Form.Item name="upgradeDp">
                    <Checkbox>升级模型</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export const publishMap = () => {
    const openModal = useOpenModal()

    return () => async function () {
        const softwareVersion = 'SA_MAP' === this.data.mapTypeCode

        const userInputs = await openModal(
            <ModalPublish softwareVersion={softwareVersion} />
        )

        if (! userInputs) {
            return
        }

        return this.runAction(
            async () => {
                const {pkid} = this.data
                const updates = await apiMap.publish({pkid, ...userInputs})
                this.data = {...this.data, ...updates}
            },

            {
                action: '发布地图',
                success: {show: true},
            },
        )
    }
}
