import runAsync from '@/script/runAsync.mjs'
import apiSnapshot from '../../apis/apiSnapshot.mjs'
import metaBizNode from '../../metaBizNode.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default pkid => {
    const {data} = useSWR(
        `/maps/${pkid}/snapshot`,

        () => runAsync(
            () => apiSnapshot.read({pkid}),
            {action: '读取地图'}
        )
    )

    if (data) {
        const map = parseMap(data)
        const {bizNodeType} = map.root.data
        const mapTypeCode = `_${bizNodeType}_MAP`
        const mapTypeName = metaBizNode[bizNodeType]?.name
        Object.assign(map.data, {mapTypeCode, mapTypeName})
        return map
    }
    else {
        return void 0
    }
}
