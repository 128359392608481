import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonJumpStruct from '../_ARCH/PropertiesPaneButtonJumpStruct.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ButtonOldVersion from '../components/PropertiesPaneButtonOldVersion/PropertiesPaneButtonOldVersion.jsx'

const PropertiesPaneBa = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonExportModel
                    global
                    type="bm"
                />

                <ButtonImportModules
                    global
                    type="bm"
                />

                <ButtonJumpStruct />
                <ButtonOldVersion>加载业务结构</ButtonOldVersion>
            </>
        }

        {...props}
    />
)

export default PropertiesPaneBa
