import {css} from '@emotion/react'
import {Button} from 'antd'
import InputText from '@/components/Form/InputText.jsx'
import buildUrl from '@/script/buildUrl.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {PropertiesController} from '../../SidePanel/RightPanel/PropertiesController.jsx'

const ButtonLastRev = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType, lastRev, sVer, pkid} = node.data

    if (! lastRev) {
        return null
    }

    const handleClick = () => {
        if (map.BizNode[bizNodeType].isProduct) {
            const {detailIdProp, detailUrl} = map.BizNode[bizNodeType]
            const url = buildUrl(detailUrl, {[detailIdProp]: pkid})
            window.open(url)
        }
        else if (map.BizNode[bizNodeType].isComponent) {
            const {ownerIdProp, ownerType} = map.BizNode[bizNodeType]
            const {detailUrl} = map.BizNode[ownerType]
            const {[ownerIdProp]: ownerId} = node.data

            const mapInits = {
                selectedNodes: [{
                    data: {pkid},
                    isMounted: true,
                }]
            }

            const url = buildUrl(
                detailUrl,
                {mapInits, [ownerIdProp]: ownerId},
            )

            window.open(url)
        }
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >最新：{sVer}.{lastRev}</Button>
    )
}

const ButtonOnlineRev = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        1 === selectedNodes.size &&
        '_DES_MAP' === map.data.mapTypeCode
    )) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType, onlineRev, rev, sVer} = node.data

    if (! onlineRev) {
        return null
    }

    const handleClick = () => {
        map.BizNode[bizNodeType].jumpToDiff(map, node, rev, onlineRev)
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >线上：{sVer}.{onlineRev}</Button>
    )
}

const ButtonUpgrade = (props) => {
    const map = useMapContext()
    const permission = map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const {canUpgradeProduct, upgradeProduct} = map.commands
    const nodes = canUpgradeProduct(selectedNodes)

    if (
        0 === nodes.length ||
        ! permission.has('write')
    ) {
        return null
    }

    const handleClick = () => upgradeProduct(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        >升级</Button>
    )
}

const cssVersion = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'end',
    gap: 4,
})

const Version = ({node, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const {bizNodeType} = node.data
    const version = map.BizNode[bizNodeType].getVersion(map, node)

    if (! version) {
        return null
    }

    return (
        <PropertiesController
            label="分支版本号"

            labelSuffix={
                <div>
                    <ButtonOnlineRev />
                    <ButtonLastRev />
                </div>
            }

            {...props}
        >
            <div css={cssVersion}>
                <InputText
                    readOnly
                    value={version}
                />

                <ButtonUpgrade />
            </div>
        </PropertiesController>
    )
}

export default function PropertiesControllerProductVersion(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 < selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes
    return <Version node={node} {...props} />
}
