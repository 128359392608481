import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import parseMap from '../../../scripts/parseMap.mjs'
import ModalMakeSf from './ModalMakeSf.jsx'

export default function PropertiesPaneButtonMakeApi(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        1 === selectedNodes.size &&
        '_UI_MAP' === map.data.mapTypeCode
    )) {
        return null
    }

    const handleClick = async () => {
        const [ue] = selectedNodes
        const {pkid: ueId, prjId, sVer, ueName} = ue.data

        const defaultRuleId = (() => {
            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                const isLinked = _n.isLinked()

                return {
                    yieldChildren: ! isLinked,
                    yieldNode: isLinked,
                }
            }

            for (const n of map.walkDown(ue, {excludeTarget: true, next})) {
                const {bizNodeType, pkid, ruleType} = n.data

                if (
                    /^(BD|SVR)LOGIC$/.test(bizNodeType) &&
                    'BT' === ruleType
                ) {
                    return pkid
                }
            }
        })()

        const args = await openModal(
            <MapContext.Provider value={map}>
                <ModalMakeSf
                    defaultRuleId={defaultRuleId}
                    defaultName={ueName}
                />
            </MapContext.Provider>
        )

        if (! args) {
            return
        }

        const treeData = await runAsync(
            () => http.post('/umuis/genBtAndSf', {prjId, sVer, ueId, ...args}),
            {action: '生成交互功能'}
        )

        if (
            args.ruleId ||
            ! treeData ||
            defaultRuleId === treeData.pkid
        ) {
            return
        }

        for (const n of ue.children) {
            const {bizNodeType} = n.data

            if ('ALG_RULE' === bizNodeType) {
                await map.cmd(async () => {
                    const ar = map.createNode('AR')

                    ar.data = {
                        ...ar.data,
                        arName: '',
                        arTypeCode: 'EXEC',
                    }

                    map.appendChild(n, ar)
                    await map.BizNode.AR.onCreate(map, ar)
                    const bdlogic = map.importTree(parseMap(treeData))
                    map.appendChild(ar, bdlogic)
                    map.selectNodes([ar])
                })

                break
            }
        }
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            生成交互功能
        </Button>
    )
}
