import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

/**
 * SelectMapVerDialog: 选择地图历史版本弹窗
 * @param id            【必填】制品/集成地图 ID
 * @param code          【可选】制品/集成地图 code
 * @param type          【可选】制品地图：bizNodeType、集成地图 mapTypeCode
 * @param prjId         【可选】项目ID
 * @param visible       【必填】当前Dialog是否展示
 * @param setVisible    【必填】当前Dialog展示的操作方法
 * @param onCall        【必填】回调函数
 * @returns {JSX.Element}
 * @constructor
 */
const SelectMapVerDialog = ({ id, code, type, prjId, visible, setVisible, onOk, ...props }) => {
    const http = useHTTP();

    // 数据模型
    const [dataList, setDataList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);

    // 组件挂载执行一次
    useEffect(() => {
        if (visible) {
            doSearch();
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);
            const data = await runAsync(
                async () => {
                    return await http.get(`/basmapvers`, { dataId: id, dataCode: code, dataType: type, prjId });
                }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

            setDataList(data);
            setSelectedRows([]);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleOk = async() => {
        if (selectedRows.length === 1) {

            // 获取历史版本数据
            const selData = await http.get(`/basmapvers/${selectedRows[0].pkid}`)

            onOk(selData);
        }
    }

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            dataIndex: 'crtTime',
            title: '发布时间',
            width: 160,
        },
        {
            dataIndex: 'crtName',
            title: '发布人',
            width: 120,
        },
        {
            title: '分支号',
            dataIndex: 'sVer',
            align: 'center',
            width: 80,
        },
        {
            dataIndex: 'dataVer',
            title: '版本号',
            width: 80,
            align: 'center',
            render: (text, record, index) => {
                return <div>{record?.dataVer ?? record?.verFlag}</div>;
            }
        },
        {
            dataIndex: 'memo',
            title: '备注',
        },
    ];

    return (
        <Modal
            visible={visible}
            width={1000}
            title='选择历史版本'
            okText="确认"
            cancelText="取消"
            onOk={handleOk}
            onCancel={() => setVisible(false)}
            bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
            {...props}
        >
            <div style={{ padding: '12px 12px 0 12px', overflow: 'hidden', background: '#fff', height: '480px', display: "flex", flexDirection: "column"}}>
                {/* 数据表格 */}
                <div style={{ marginTop: '0px', overflow: 'auto', background: '#fff'}}>
                    {/* 数据区 */}
                    <div style={{ marginBottom: '0'}}>
                        <Table
                            size="middle"
                            loading={loading}
                            columns={columns}
                            dataSource={dataList}
                            pagination={Page.init(dataList)}
                            rowKey={record => record.pkid}
                            rowSelection={{ type: "checkbox", ...rowSelection }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SelectMapVerDialog;
