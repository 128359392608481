import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonJumpStruct from '../_ARCH/PropertiesPaneButtonJumpStruct.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ButtonOldVersion from '../components/PropertiesPaneButtonOldVersion/PropertiesPaneButtonOldVersion.jsx'

const PropertiesPaneUa = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonImportModules type="um" />
                <ButtonJumpStruct />
                <ButtonOldVersion>加载界面结构</ButtonOldVersion>
            </>
        }

        {...props}
    />
)

export default PropertiesPaneUa
