import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import ImportBmDialog from '@/pages/MainPage/dialogs/ImportBmDialog.jsx'
import ImportFmDialog from '@/pages/MainPage/dialogs/ImportFmDialog.jsx'
import ImportUmDialog from '@/pages/MainPage/dialogs/ImportUmDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonImportModules({type, ...props}) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType} = node.data

    if (! map.BizNode[bizNodeType].canWriteTree(map, node)) {
        return null
    }

    const Modal = {
        bm: ImportBmDialog,
        fm: ImportFmDialog,
        um: ImportUmDialog,
    }[type]

    const handleClick = async () => {
        const {pkid: prjId, prjNo, sVer} = map.data.project
        await openModal(<Modal {...{prjId, prjNo, sVer}} />)
        map.commands.refreshProduct([map.root])
    }

    const name = {
        bm: '业务',
        fm: '功能',
        um: '界面',
    }[type]

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导入{name}结构
        </Button>
    )
}
