import http from '@/biz/http.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import Color from '../Color.mjs'
import meta from './metaCatData.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        getRev(map, node) {
            return map.BizNode.BA.getRev.call(this, map, node)
        },

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: Color.CYAN,
            }
        },

        getVersion(map, node) {
            return map.BizNode.BA.getVersion.call(this, map, node)
        },

        isOutdated(map, node) {
            return map.BizNode.BA.isOutdated.call(this, map, node)
        },

        async shrink(map, node) {
            map.deleteChildren(node)
        },

        onDoubleClick(map, node, event) {
            const {pkid: prjId, prjNo, sVer} = map.data.project
            const url = buildUrl('/ProjectDataMap', {prjId, prjNo, sVer})
            window.open(url)
        },

        async _grow(map, node, depth) {
            const tree = await this._readGrowTree(map, node)

            if (! tree) {
                return depth
            }

            await this.replace(map, node, tree)
            return depth + 1
        },

        async _readGrowTree(map, node) {
            //const {prjId, sVer} = map.data
            //const {baMap} = await http.get(`/archbas/is/${prjId}/${sVer}`)
            //const {root: {children}} = parseMap(baMap)
            //return {children}

            for (const n of map.walkDown(map.root, {breadthFirst: true})) {
                if ('BA' === n.data.bizNodeType) {
                    const _n = map.nodeProxy(n)

                    if (n.firstChild) {
                        const tree = _n.exportTree()

                        const refine = nodes => {
                            return nodes
                                .filter(n => 'BM' === n.data.bizNodeType)
                                .map(n => {
                                    const children = refine(n.children)
                                    return {...n, children}
                                })
                        }

                        const children = refine(tree.children)
                        return {children}
                    }
                    else {
                        const {children} = await _n._readGrowTree()
                        return {children}
                    }
                }
            }
        },
    })
}
