import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaBa.mjs'
import PropertiesPane from './PropertiesPaneBa.jsx'
import api from './apiBa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'BM'
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archBmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_YELLOW,
            })
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archBmList'])
        },

        onAttached(map, node, child) {
            map.BizNode.BM.onAttached(map, node, child)
        },

        async onSetData(map, node, oldData) {
            const {lastRev, rev, sVer} = node.data

            if (
                rev !== oldData.rev &&
                'SA_MAP' === map.data.mapTypeCode
            ) {
                for (const n of map.walkDown(map.root, {breadthFirst: true})) {
                    if ('CAT_DATA' === n.data.bizNodeType) {
                        n.data = {...n.data, lastRev, rev, sVer}
                        break
                    }
                }
            }
        },
    })
}
